import axios from "axios";

const useCoreApi = () => {
    const applicationUrl = process.env.REACT_APP_API_URL;

    const defaultHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    };

    const fetchWalletBalanceData = async () => {
        return await axios.get(applicationUrl + "v1/logged/wallet/getData", {
            headers: defaultHeaders
        });
    };

    return { fetchWalletBalanceData };
};

export default useCoreApi;
const SimpleFooter = () => {
    return (<footer>
        <div className={"flex flex-col"}>
            <div className="bg-[#833014] py-6 text-[#ed9071] mx-auto bottom-0 w-full">
                <div className="max-w-[1920px] mx-auto px-4">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                        <div></div>

                        <div className="mb-4 md:mb-0">
                            <h3 className="text-lg font-bold mb-2">Links Úteis</h3>
                            <ul>
                                <li><a href="#" className="hover:underline">Página Inicial</a></li>
                                <li><a href="#" className="hover:underline">Sobre Nós</a></li>
                                <li><a href="#" className="hover:underline">Termos e Condições</a></li>
                                <li><a href="#" className="hover:underline">Política de Privacidade</a></li>
                            </ul>
                        </div>

                        <div className="mb-4 md:mb-0">
                            <h3 className="text-lg font-bold mb-2">Contato</h3>
                            <ul className={"bg-[#64210a] px-2 py-2 rounded"}>
                                <li>
                                    <a href="mailto:sac@ddtanksplush.com.br" className="hover:underline">
                                        sac@ddtanksplush.com.br
                                    </a> - Atendimento ao cliente
                                </li>
                                <li>
                                    <a href="mailto:comercial@ddtanksplush.com.br" className="hover:underline">
                                        comercial@ddtanksplush.com.br
                                    </a> - Parcerias e assuntos comerciais
                                </li>
                                <li>
                                    <a href="mailto:suporte@ddtanksplush.com.br" className="hover:underline">
                                        suporte@ddtanksplush.com.br
                                    </a> - Suporte técnico
                                </li>
                                <li>
                                    <a href="mailto:financeiro@ddtanksplush.com.br" className="hover:underline">
                                        financeiro@ddtanksplush.com.br
                                    </a> - Financeiro e cobranças
                                </li>
                                <li>
                                    <a href="mailto:privacidade@ddtanksplush.com.br" className="hover:underline">
                                        privacidade@ddtanksplush.com.br
                                    </a> - Proteção de dados
                                </li>
                            </ul>

                        </div>

                        <div></div>
                    </div>
                </div>
            </div>

            <div className="flex bg-[#64210a] py-6 text-[#ed9071] mx-auto bottom-0 w-full justify-center items-center">
                &copy; Todos os direitos reservados. DDTank Splush e seus parceiros.
            </div>
        </div>
    </footer>);
}

export default SimpleFooter;
import SimpleHeader from "../../components/simple/simple-header";
import SimpleFooter from "../../components/simple/simple-footer";
import {useNavigate} from "react-router-dom";
import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleNotice from "../../components/simple/simple-notice";

const DashboardHome = () => {
    const navigate = useNavigate();

    let user = JSON.parse(localStorage.getItem("user"));

    return (

        <div className="flex flex-col min-h-screen">
            <SimpleHeader></SimpleHeader>

            <main
                className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center w-full">
                <div className="flex-none">
                    <div className="flex flex-col sm:flex-col md:flex-row md:flex-wrap lg:flex-row lg:flex-wrap gap-4">
                        <SimpleDashboardMenu></SimpleDashboardMenu>

                        <div
                            className="flex-1 bg-white rounded-[60px] lg:min-w-[700px] h-[400px] shadow-lg flex-shrink-0">
                            <SimpleNotice></SimpleNotice>

                            {/*<div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">*/}
                            {/*    <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>*/}
                            {/*    <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Página Inicial</h2>*/}
                            {/*</div>*/}

                            {/*<div className="flex justify-between">*/}
                            {/*    Home*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </main>

            <SimpleFooter></SimpleFooter>
        </div>

    );
};

export default DashboardHome;
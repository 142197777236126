const SimpleHeader = () => {
    return (<header className="flex items-center mx-auto relative w-full">
        <div className="flex justify-between bg-[#833014] w-full py-4 px-6">
            <div className="pl-4 hidden sm:block">
                <img src="/img/logo.png" className="h-[55px]" alt="Logo"></img>
            </div>

            <div className="flex flex-wrap items-center justify-center gap-4">
                <a href={"https://www.facebook.com/DDtankSplushBrasil"}
                    className="bg-[#772a10] hover:bg-[#5d200c] text-[#ed9071] rounded-[20px] px-4 py-3 sm:w-auto w-full" target={"_blank"}>
                    Página Facebook
                </a>
                <a href={"https://chat.whatsapp.com/DmRkrUwuGtd6h1rqyOSTqI"}
                    className="bg-[#772a10] hover:bg-[#5d200c] text-[#ed9071] rounded-[20px] px-4 py-3 sm:w-auto w-full" target={"_blank"}>
                    Grupo WhatsApp
                </a>
                <a href={"https://discord.gg/JDjGFJjZ"}
                    className="bg-[#772a10] hover:bg-[#5d200c] text-[#ed9071] rounded-[20px] px-4 py-3 sm:w-auto w-full" target={"_blank"}>
                    Grupo Discord
                </a>
            </div>
        </div>
    </header>);
}

export default SimpleHeader;
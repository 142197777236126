import {Button, Modal} from "flowbite-react";
import {NumericFormat} from "react-number-format";
import QRCode from "react-qr-code";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";

const DepositModalComponent = ({ openModal, setOpenModal }) => {
    const [modalPlacement, setModalPlacement] = useState('center')
    const [depositValue, setDepositValue] = useState(0);
    const [depositQrcodeInfo, setDepositQrcodeInfo] = useState(null);
    const [followDepositRef, setFollowDepositRef] = useState(null);

    const handleDepositValueChange = (values) => {
        setDepositValue(values.floatValue);
    };

    const handleDepositCancel = () => {
        setDepositQrcodeInfo(null);
        setOpenModal(false);
    };

    async function onDepositBtnClick() {

        Swal.showLoading();
        try {

            let createDepositAxios = await axios.post(
                `${process.env.REACT_APP_API_URL}v1/logged/wallet/create-deposit`,
                {
                    gateway: "pix",
                    amount: depositValue,
                    currencyShort: "BRL"
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );

            if (!createDepositAxios.data.success) {
                Swal.fire("Ocorreu um erro ao gerar o QR Code para o pagamento. Por favor, tente novamente em instantes.")
                return;
            }

            setFollowDepositRef(createDepositAxios.data.data.internalRef);
            setDepositQrcodeInfo(createDepositAxios.data.data);
        } catch (e) {
            Swal.fire("", e.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }
        //alert(depositValue);
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            if (followDepositRef != null) {

                try {
                    let checkDepositAxios = await axios.post(
                        `${process.env.REACT_APP_API_URL}v1/logged/wallet/check-deposit-is-paid`,
                        {
                            reference: followDepositRef
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    );

                    if (checkDepositAxios.data.success) {
                        await Swal.fire("", "Seu pagamento foi identificado!", "success");
                        window.location.reload();
                    }
                } catch (err) {
                    //console.error(err);
                }

            }
        }, 5000);

        // Limpar o intervalo quando o componente for desmontado ou quando o ref for atualizado
        return () => clearInterval(interval);
    }, [followDepositRef]);

    return (
        <>
            <Modal
                show={openModal}
                position={modalPlacement}
                onClose={handleDepositCancel}

            >
                <Modal.Header className={"bg-[#67240e]"}><span
                    className={"text-xl text-[#ed9071] font-bold uppercase pl-4"}>Novo depósito</span></Modal.Header>
                <Modal.Body>
                    <div className="space-y-6 p-6">
                        {
                            depositQrcodeInfo == null ?
                                (<>
                                    <div className="flex flex-col py-4 px-6">
                                        <div>
                                            <label htmlFor="email" className="text-[#67240e] font-bold">Valor do
                                                depósito</label>
                                            <NumericFormat
                                                id="valor"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix="R$ "
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowNegative={false}
                                                placeholder="Digite o valor"
                                                className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                                                type="text"
                                                onValueChange={handleDepositValueChange}
                                            />
                                        </div>
                                    </div>
                                </>) : (
                                    <div className={"flex flex-col items-center"}>
                                        <div className={"text-xl text-[#67240e] font-bold uppercase pl-4 mb-2"}>
                                            Valor a pagar R$ { depositQrcodeInfo.price }
                                        </div>
                                        <QRCode value={ depositQrcodeInfo.copy_paster } className={"rounded mb-4"} />
                                        <div className={"text-xl text-[#67240e] font-bold uppercase pl-4 mb-2"}>
                                            Deve ser pago até as { depositQrcodeInfo.endTime }
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"flex w-full justify-end gap-2"}>
                        <Button onClick={onDepositBtnClick} color={"green"}>Depositar</Button>
                        <Button color="red" onClick={handleDepositCancel}>
                            Cancelar
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DepositModalComponent;
import axios from "axios";
import Swal from "sweetalert2";

const useCasinoApi = () => {
    const applicationUrl = process.env.REACT_APP_API_URL;

    const fetchGames = async () => {

        try {
            let response = await axios.get(applicationUrl + "v1/casino/game-list");

            if (response.status === 200) {
                return response.data.data;
            }

            return [];
        } catch (error) {
            //console.error(error);
            return [];
        }

    };

    const playGame = async (slug) => {
        try {

            let response = await axios.post(applicationUrl + `v1/casino/${slug}/play`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.status === 200) {
                return response.data.data;
            }

        } catch (error) {
            return "";
        }
    };

    return { fetchGames, playGame };
};

export default useCasinoApi;
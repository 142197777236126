import SimpleHeader from "../components/simple/simple-header";
import SimpleFooter from "../components/simple/simple-footer";
import SimpleLogin from "../components/simple/simple-login";
import SimpleNotice from "../components/simple/simple-notice";
import SimplePlay from "../components/simple/simple-play";
import SimpleGamble from "../components/simple/simple-gamble";
import {useState} from "react";

const Home = () => {
    const [gambleGameName, setGambleGameName] = useState("");
    const [playingGambleGame, setPlayingGambleGame] = useState(null);

    const onSuccessCasinoGameAuth = (launchUrl, gameName) => {
        setGambleGameName(gameName);
        setPlayingGambleGame(launchUrl);
    };

    const handleCloseGame = () => {
        window.dispatchEvent(new CustomEvent("balanceUpdate"));
        setPlayingGambleGame(null);
    }

    return (
        <div className="flex flex-col min-h-screen">
            <SimpleHeader></SimpleHeader>

            <main
                className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center w-full">

                <div className="flex-none mb-8 mt-4">
                    <img src="/img/logo.png"
                         className="h-auto hidden xs:block max-w-[300px] sm:max-w-[400px] md:max-w-[480px]"
                         alt="Logo do servidor"></img>
                </div>

                <div className="flex xs:flex-col xl:flex-row gap-4">
                    {/*Card esquerdo*/}
                    <div
                        className={"flex-1 xs:max-w-[290px] sm:max-w-[400px] md:max-w-[480px] lg:max-w-[520px] overflow-hidden gap-4 mb-5"}>

                        <div className={"flex flex-col gap-4"}>
                            <div
                                className={`flex-1 bg-white rounded-[60px] ${localStorage.getItem("token") ? "xs:min-h-[500px]" : ""} sm:min-h-[400px] lg:min-h-[400px] shadow-lg flex-shrink-0`}>
                                {
                                    localStorage.getItem("token") ? (<SimplePlay/>) : (<SimpleLogin/>)
                                }
                            </div>

                            {/*<div className={`flex-1 bg-white rounded-[60px] ${localStorage.getItem("token") ? "xs:min-h-[500px]" : ""} sm:min-h-[400px] lg:min-h-[400px] shadow-lg flex-shrink-0`}>*/}

                            {/*</div>*/}
                        </div>

                    </div>

                    {/*Card direito*/}
                    {
                        localStorage.getItem("token") ?
                            (
                                <div className="flex hidden md:block xl:w-[700px] bg-white rounded-[60px] mb-5">
                                    <SimpleGamble gameAuthClosure={onSuccessCasinoGameAuth}></SimpleGamble>
                                </div>
                            ) :
                            (
                                <div className="flex max-h-[400px] hidden md:block xl:w-[600px] bg-white rounded-[60px] mb-5">
                                    <SimpleNotice/>
                                </div>
                            )
                    }
                </div>

            </main>

            {
                playingGambleGame != null ?
                    (<div id="gamblePlayGame"
                          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-black p-2 h-screen w-screen fixed">
                            <div className="flex justify-between items-center px-4 mb-2">
                                <h2 className="text-md text-white font-semibold">Jogando {gambleGameName}</h2>
                                <button onClick={handleCloseGame} className="text-red-500">Fechar</button>
                            </div>

                            <iframe src={playingGambleGame} className="w-full h-full"></iframe>
                        </div>
                    </div>) : (<div></div>)
            }

            <SimpleFooter></SimpleFooter>
        </div>
    );
}

export default Home;
import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleFooter from "../../components/simple/simple-footer";
import SimpleHeader from "../../components/simple/simple-header";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";

const DashboardAffiliates = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const [totalEarnings, setTotalEarnings] = useState(0);
    const [affiliateList, setAffiliateList] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        let fetchUserAffiliates = async () => {

            try {

                let affiliateAxios = await axios.get(`${process.env.REACT_APP_API_URL}v1/logged/affiliate/getData?page=${currentPage}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                });

                let data = affiliateAxios.data.data;
                let affiliates = data.affiliates;
                let totalEarnings = data.totalEarnings;

                setAffiliateList(affiliates.data);
                setTotalEarnings(totalEarnings);

                setCurrentPage(data.affiliates.current_page);
                setLastPage(data.affiliates.last_page);
            } catch (err) {
                await Swal.fire("", "Error fetching data.", "error");
            }

        };

        fetchUserAffiliates();
    }, [currentPage]);

    function copyReferralLink() {
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/sign-up?ref=${user.brokerage_ref_code}`)
            .then(() => {
                Swal.fire("", "Código de referência copiado!", "success");
            })
            .catch(err => {
                Swal.fire("", "Não foi possível copiar o código de referência!", "error");
            });
    }

    const handlePageChange = (page) => {
        if (page < 1) return;
        if (page > lastPage) return;

        console.log(page);
        setCurrentPage(page);
    };

    return (
        <>

            <div className="flex flex-col min-h-screen">
                <SimpleHeader></SimpleHeader>

                <main
                    className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center w-full">
                    <div className="flex-none">
                        <div className="flex flex-wrap gap-4">
                            <SimpleDashboardMenu></SimpleDashboardMenu>

                            <div
                                className="flex-1 bg-white rounded-[60px] min-w-[700px] h-auto shadow-lg flex-shrink-0">
                                <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                                    <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                                    <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Afiliados</h2>
                                </div>

                                <div className="flex justify-center gap-4 px-4 py-4">
                                    <div className="flex flex-col gap-4 w-full">
                                        <div className="flex w-full justify-between">
                                            <div
                                                className="flex bg-[#9b3a1a] w-full rounded rounded-r-[30px] z-10 px-4 py-4">
                                                <div className="text-white font-sans uppercase">
                                                    Ganhos Totais: <span className="font-bold">R$ {totalEarnings}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="flex items bg-red-200 w-full rounded rounded-l-lg -ml-10 px-4 py-4 items-center justify-end cursor-pointer"
                                                onClick={copyReferralLink}>
                                                <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em"
                                                     width="1em" className="text-[#9b3a1a]">
                                                    <path
                                                        d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"/>
                                                </svg>
                                                <span
                                                    className="font-bold text-[#9b3a1a]">{user.brokerage_ref_code}</span>
                                            </div>
                                        </div>

                                        <div className="grid sm:grid-cols-1 md:grid-cols-2 w-full gap-2">


                                            {
                                                affiliateList != null ? (
                                                    affiliateList.map((item) => {
                                                        return (
                                                            <div
                                                                className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                                <div className="flex items-center gap-2 font-sans">
                                                                    <img
                                                                        src={item.affiliate_user.profile_photo_url}
                                                                        className="rounded-full h-12"></img>
                                                                    <div className="flex flex-col">
                                                                        <div
                                                                            className="font-bold text-[#67240e] text-lg">{item.affiliate_user.name}
                                                                        </div>
                                                                        <div
                                                                            className="font-bold text-[#934329] uppercase text-sm">Level &nbsp;
                                                                            {item.level}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <div
                                                                        className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                                        R$ {item.earnings}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (<div>Carregando...</div>)
                                            }

                                        </div>

                                        {
                                            (lastPage > 1) ? (
                                                <div className="flex justify-center my-4">
                                                    <nav className="flex items-center">
                                                        <button
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            className="px-4 py-2 border rounded-l-md text-gray-700 bg-white border-gray-300 hover:bg-gray-100"
                                                        >
                                                            Anterior
                                                        </button>

                                                        {[...Array(lastPage)].map((_, index) => (
                                                            <button
                                                                key={index + 1}
                                                                onClick={() => handlePageChange(index + 1)}
                                                                className={`px-4 py-2 border-t border-b border-gray-300 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-white'} text-gray-700 hover:bg-gray-100`}
                                                            >
                                                                {index + 1}
                                                            </button>
                                                        ))}

                                                        <button
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            className="px-4 py-2 border rounded-r-md text-gray-700 bg-white border-gray-300 hover:bg-gray-100"
                                                        >
                                                            Próximo
                                                        </button>
                                                    </nav>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </main>

                <SimpleFooter></SimpleFooter>
            </div>

        </>
    );
};

export default DashboardAffiliates;
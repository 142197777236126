import SimpleHeader from "../../components/simple/simple-header";
import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleFooter from "../../components/simple/simple-footer";
import {useEffect, useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";

const DashboardAccount = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const [personalDetailToggle, setPersonalDetailToggle] = useState(false);
    const [securityDetailToggle, setSecurityDetailToggle] = useState(false);

    const [personalFormData, setPersonalFormData] = useState({
        name: user.name,
        birthday: user.birthday ?? '',
        document: user.document ?? '',
        whatsapp: user.whatsapp ?? '',
    });

    const [passwordFormData, setPasswordFormData] = useState({
        password: '',
        password_confirmation: '',
        password_old: ''
    });

    const handlePasswordFormChange = (e) => {
        const { name, value } = e.target;
        setPasswordFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePersonalFormChange = (e) => {
        const { name, value } = e.target;
        setPersonalFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handlePersonalToggle = () => {
        // Quando os detalhes pessoais são ativados, desativa os detalhes de segurança
        setPersonalDetailToggle(prev => {
            if (!prev) {
                setSecurityDetailToggle(false);
            }
            return !prev;
        });
    };

    const handleSecurityToggle = () => {
        // Quando os detalhes de segurança são ativados, desativa os detalhes pessoais
        setSecurityDetailToggle(prev => {
            if (!prev) {
                setPersonalDetailToggle(false);
            }
            return !prev;
        });
    };

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();

        Swal.showLoading();
        try {
            let changePasswordAxios = await axios.post(`${process.env.REACT_APP_API_URL}v1/logged/user/change-password`, passwordFormData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            localStorage.removeItem("token");
            localStorage.removeItem("user");

            await Swal.fire("", changePasswordAxios.data.message, "success");

            window.location = "/";
        } catch (error) {
            let response = error.response;
            await Swal.fire("", response.data.message, "error");
            //console.log(response);
        } finally {
            Swal.close();
        }
    };

    const handleChangePersonalInfoSubmit = async (e) => {
        e.preventDefault();

        Swal.showLoading();
        try {
            let changeDetailAxios = await axios.post(`${process.env.REACT_APP_API_URL}v1/logged/user/change-detail`, personalFormData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            let user = changeDetailAxios.data.data.user;
            localStorage.setItem("user", JSON.stringify(user));

            await Swal.fire("", changeDetailAxios.data.message, "success");
        } catch (error) {
            let response = error.response;
            await Swal.fire("", response.data.message, "error");
        } finally {
            Swal.close();
        }
    };

  return (
      <div className="flex flex-col min-h-screen">
          <SimpleHeader></SimpleHeader>

          <main
              className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center w-full">
              <div className="flex-none">
                  <div className="flex flex-wrap gap-4">
                      <SimpleDashboardMenu></SimpleDashboardMenu>

                      <div
                          className="flex-1 bg-white rounded-[60px] lg:min-w-[700px] h-[500px] shadow-lg flex-shrink-0">

                          <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                              <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                              <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Minha Conta</h2>
                          </div>

                          <div className="flex flex-col gap-2 h-full items-center px-4 py-4">
                              <div
                                  className={`flex justify-between bg-[#67240e] w-[700px] py-4 px-6 transition-all duration-300 ease-in-out rounded-[10px] ${personalDetailToggle ? "" : "hover:bg-[#893317]"}`}>
                                  <div className="flex flex-col">
                                <span
                                    className="text-[#ed9071] uppercase font-bold mb-2 select-none">Detalhes pessoais</span>
                                      {
                                          !personalDetailToggle ? (<div>
                                         <span className="text-white break-words w-[600px] select-none">
                                            Aqui você pode alterar suas informações, como foto de perfil, nome, data de nascimento, documento, etc.
                                        </span>
                                          </div>) : (<div>
                                              <div className="flex flex-col gap-4">
                                                  <div className="flex gap-4">
                                                      <div>
                                                          <label htmlFor="name"
                                                                 className="block text-white mb-2">Nome</label>
                                                          <input type="text" placeholder="Nome" name="name"
                                                                 value={personalFormData.name}
                                                                 onChange={handlePersonalFormChange}
                                                                 className="px-2 py-2 rounded-[7px]"></input>
                                                      </div>

                                                      <div>
                                                          <label htmlFor="date"
                                                                 className="block text-white mb-2">Nascimento</label>
                                                          <input type="date" placeholder="Data de nascimento"
                                                                 name="birthday"
                                                                 value={personalFormData.birthday}
                                                                 onChange={handlePersonalFormChange}
                                                                 className="px-2 py-2 rounded-[7px]"></input>
                                                      </div>
                                                  </div>

                                                  <div className="flex gap-4">
                                                      <div>
                                                          <label htmlFor="date"
                                                                 className="block text-white mb-2">CPF</label>
                                                          <input type="text" placeholder="Documento" name="document"
                                                                 value={personalFormData.document}
                                                                 onChange={handlePersonalFormChange}
                                                                 className="px-2 py-2 rounded-[7px]"></input>
                                                      </div>
                                                      <div className="flex justify-between gap-4 items-end">
                                                          <div>
                                                              <label htmlFor="date"
                                                                     className="block text-white mb-2">WhatsApp</label>
                                                              <input type="text" placeholder="Telefone" name="whatsapp"
                                                                     value={personalFormData.whatsapp}
                                                                     onChange={handlePersonalFormChange}
                                                                     className="px-2 py-2 rounded-[7px]"></input>
                                                          </div>

                                                          <button
                                                              className="bg-[#431607a3] py-2 px-4 rounded-[7px] text-white font-bold uppercase"
                                                              onClick={handleChangePersonalInfoSubmit}>Salvar
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>)
                                      }
                                  </div>

                                  <img draggable="false"
                                       src={!personalDetailToggle ? "/img/profile/next-btn-96.png" : "/img/profile/close-btn-96.png"}
                                       className={`h-12 cursor-pointer ${!personalDetailToggle ? "/img/profile/next-btn-96.png" : "/img/profile/close-btn-96.png"}`}
                                       alt="Ir para informações básicas" onClick={handlePersonalToggle}></img>
                              </div>

                              <div
                                  className={`flex justify-between bg-[#67240e] w-[700px] transition-all duration-300 py-4 px-6 rounded-[10px] mb-10 ${securityDetailToggle ? "" : "hover:bg-[#893317]"}`}>
                                  <div className="flex flex-col">
                                <span
                                    className="text-[#ed9071] uppercase font-bold mb-2 select-none">Segurança e Acesso</span>
                                      {
                                          !securityDetailToggle ? (<div>
                                      <span className="text-white break-words w-[590px] select-none">
                                        Aqui você pode alterar o endereço de e-mail, senha e outros dados de acesso, além de visualizar informações de segurança, como os últimos acessos, etc.
                                      </span>
                                          </div>) : (<div>

                                              <div className="flex flex-col gap-4">
                                                  <div className="flex gap-2">
                                                      <div>
                                                          <label htmlFor="name" className="block text-white mb-2">Nova
                                                              senha</label>
                                                          <input name="password" type="password" placeholder="********"
                                                                 className="px-2 py-2 rounded-[7px] disabled:text-red-500"
                                                                 value={passwordFormData.password}
                                                                 onChange={handlePasswordFormChange}></input>
                                                      </div>

                                                      <div>
                                                          <label htmlFor="name" className="block text-white mb-2">Confirme
                                                              a
                                                              nova senha</label>
                                                          <input name="password_confirmation" type="password"
                                                                 placeholder="********"
                                                                 className="px-2 py-2 rounded-[7px] disabled:text-red-500"
                                                                 value={passwordFormData.password_confirmation}
                                                                 onChange={handlePasswordFormChange}></input>
                                                      </div>
                                                  </div>

                                                  <div className="flex gap-2 items-end">
                                                      <div>
                                                          <label htmlFor="name" className="block text-white mb-2">Senha
                                                              atual</label>
                                                          <input name="password_old" type="password"
                                                                 placeholder="********"
                                                                 className="px-2 py-2 rounded-[7px] disabled:text-red-500"
                                                                 value={passwordFormData.password_old}
                                                                 onChange={handlePasswordFormChange}></input>
                                                      </div>

                                                      <div>
                                                          <button
                                                              className="bg-[#431607a3] py-2 px-4 rounded-[7px] text-white font-bold uppercase"
                                                              onClick={handleChangePasswordSubmit}>Alterar
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>

                                          </div>)
                                      }
                                  </div>
                                  <img draggable="false"
                                       src={!securityDetailToggle ? "/img/profile/next-btn-96.png" : "/img/profile/close-btn-96.png"}
                                       className={`h-12 cursor-pointer ${!securityDetailToggle ? "/img/profile/next-btn-96.png" : "/img/profile/close-btn-96.png"}`}
                                       alt="Ir para informações básicas"
                                       onClick={handleSecurityToggle}></img>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </main>

          <SimpleFooter></SimpleFooter>
      </div>
  );
};

export default DashboardAccount;
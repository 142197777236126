import SimpleHeader from "../../components/simple/simple-header";
import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleFooter from "../../components/simple/simple-footer";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import SimplePaymentWaiting from "../../components/simple/payment/simple-payment-waiting";
import SimplePaymentCompleted from "../../components/simple/payment/simple-payment-completed";
import axios from "axios";

const DashboardWaitPayment = () => {
    const location = useLocation();
    const { paymentInfo, method, ref } = location.state || {};
    const [completedPayment, setCompletedPayment] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(async () => {

            try {

                let paymentStatus = await axios.get(`${process.env.REACT_APP_API_URL}v1/logged/fast-recharge/order-status/${paymentInfo.i_external_ref}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                });

                let response = paymentStatus.data.data;
                if(response.completed)
                    setCompletedPayment(true);

            } catch (err) {
                //console.error(err);
            }

        }, 3000);

        // Limpeza do intervalo ao desmontar o componente
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <SimpleHeader></SimpleHeader>

            <main
                className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center w-full">
                <div className="flex-none">
                    <div className="flex flex-wrap gap-4">
                        <SimpleDashboardMenu></SimpleDashboardMenu>

                        {
                            !completedPayment ? <SimplePaymentWaiting></SimplePaymentWaiting> : (
                                <SimplePaymentCompleted></SimplePaymentCompleted>)
                        }
                    </div>
                </div>
            </main>

            <SimpleFooter></SimpleFooter>
        </div>
    );
};

export default DashboardWaitPayment;
import {useEffect, useState} from "react";
import useCasinoApi from "../../api/casinoapi";
import Swal from "sweetalert2";
import useCoreApi from "../../api/coreapi";
import DepositModalComponent from "../wallet/depositmodalcomponent";

const SimpleGamble = ({gameAuthClosure}) => {
    const {fetchGames, playGame} = useCasinoApi();
    const {fetchWalletBalanceData} = useCoreApi();

    const [gameList, setGameList] = useState([]);
    const [brlValue, setBrlValue] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [gamesLoading, setGamesLoading] = useState(true);

    useEffect(() => {

        async function fetchGameData() {
            setGamesLoading(true);
            let gameList = await fetchGames();
            setGameList(gameList);
            setGamesLoading(false);
        }

        fetchGameData();
        updateWalletBalance();

        window.addEventListener("balanceUpdate", updateWalletBalance);
    }, []);

    async function updateWalletBalance() {
        let response = await fetchWalletBalanceData();

        if (!response.data.success) {
            await Swal.fire("", "Não conseguimos obter seu saldo!", "error");
            return;
        }

        let brlInfo = response.data.data.balances.BRL;
        setBrlValue(parseFloat(brlInfo.available_value / 100));
    }

    async function handlePlayGameBtnClick(slug, name) {

        Swal.showLoading();
        let response = await playGame(slug);
        Swal.close();

        if (response === "") {
            await Swal.fire("", "O jogo selecionado está temporariamente em manutenção. Por favor, tente novamente mais tarde!", "error");
            return;
        }

        gameAuthClosure(response.launch_url, name);
    }

    const handleUpdateBalance = async () => {
        setIsSpinning(true);

        await updateWalletBalance();

        setIsSpinning(false);
    };


    return (
        <>
            <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Diversão extra</h2>
            </div>

            <div className="flex flex-col px-4 py-4 md:max-w-[400px] lg:max-w-[400px] xl:max-w-[1000px] mb-[20px]">
                <div className={"flex flex-row gap-1"}>
                    <div className={"flex w-3/4 justify-center bg-[#c14b24] px-2 py-4 mb-4 rounded bg-opacity-40"}>
                        <div className={"flex items-center gap-2 font-bold text-[#67240e] uppercase"}>
                            Saldo atual: R${brlValue} <img src={"/img/casino/reload-money.png"}
                                                           className={`h-4 cursor-pointer ${isSpinning ? "animate-spin" : ""}`}
                                                           onClick={handleUpdateBalance} alt={"Atualizar saldo"}></img>
                        </div>
                    </div>
                    <button
                        className={"flex w-1/4 justify-center bg-[#c14b24] hover:bg-[#bc6447] px-2 py-4 mb-4 rounded bg-opacity-80 text-white font-bold uppercase"}
                        onClick={() => setOpenModal(true)}>
                        Depositar
                    </button>
                </div>
                <div>
                    <div className={"flex justify-between"}>
                        <div>
                            <span className={"text-2xl uppercase font-bold"}>Jogos Populares</span>
                        </div>
                        <div>
                            Ver mais
                        </div>
                    </div>

                    {
                        gamesLoading ? (
                                <div className="flex justify-center h-full items-center py-6">
                                    <div className="loader">
                                        <div role="status">
                                            <svg aria-hidden="true"
                                                 className="inline w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"/>
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"/>
                                            </svg>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (

                                <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-2 w-full h-full">
                                    {
                                        gameList.map((game, index) => (
                                            <div
                                                key={index}
                                                className="flex items-end justify-center text-white font-bold cursor-pointer animate-fade-in-up"
                                                style={{animationDelay: `${index * 0.1}s`}} // Delay progressivo para cada item
                                                onClick={() => handlePlayGameBtnClick(game.slug, game.name)}
                                            >
                                                <img
                                                    src={game.image_url}
                                                    alt={"Jogo da PG"}
                                                    draggable={false}
                                                    className="rounded-[20px] border-[#67240e] border-opacity-40 border-[3px]"
                                                />
                                                <span
                                                    className="absolute text-xs mb-3 pointer-events-none">{game.name}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                    }

                    <div className={"mt-4"}>
                        <span className={"text-red-600 font-bold"}>Atenção:</span> Jogos de cassino não devem ser
                        encarados como uma forma de investimento ou fonte de
                        renda extra. Jogue com responsabilidade, sempre por diversão. Se você tem menos de <span
                        className={"text-red-600 font-bold"}>18 anos</span>,
                        <span
                            className={"text-green-600 font-bold cursor-pointer"}>&nbsp; clique aqui &nbsp;</span> para
                        não visualizar mais este conteúdo.
                    </div>
                </div>
            </div>

            <DepositModalComponent openModal={openModal} setOpenModal={setOpenModal}></DepositModalComponent>
        </>
    );
}

export default SimpleGamble;
